import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import AllArticles from "../components/AllArticles"
import Seo from "../components/Seo"
import { Helmet } from "react-helmet"
import biglog from "../assets/images/biglog.png"
import religious from "../assets/images/religious.png"
import stuff from "../assets/images/stuff.png"
import logolog from "../assets/images/logolog.png"

export const index = () => {
  return (
    <Layout>
      <Helmet></Helmet>
      <Seo title="Home Page" />
      <main className="page">
        <div className="divlog">
          <div className="divtxt">
            <StaticImage src="../assets/images/biglog.png" className="biglog" />
          </div>
        </div>
        <header className="hero">
          <StaticImage
            src="../assets/images/main.JPG"
            alt="icones shop"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
        </header>
      </main>
    </Layout>
  )
}

export default index
